import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterReducerState, getSelectors } from '@ngrx/router-store';
import { State, featureKey } from './state';

const selectState = createFeatureSelector<State>(featureKey);
const selectRouterState = createFeatureSelector<RouterReducerState>('router');

export const {
    selectCurrentRoute, // select the current route
    selectQueryParams, // select the current route query params
    selectQueryParam, // factory function to select a query param
    selectRouteParams, // select the current route params
    selectRouteParam, // factory function to select a route param
    selectRouteData, // select the current route data
    selectUrl, // select the current url
} = getSelectors(selectRouterState);

export const selectWellId = createSelector(selectState, ({ wellId }) => wellId);

export const selectWell = createSelector(selectState, ({ well }) => well);

export const selectMenuOptions = createSelector(
    selectState,
    ({ well }) => (well && well.menuOptions) || []
);

export const selectWellOptions = createSelector(
    selectState,
    ({ well }) => well.options
);

export const selectWellHeader = createSelector(
    selectState,
    ({ well }) => (well && well.header) || null
);

export const selectIsLoading = createSelector(
    selectState,
    ({ isLoading }) => isLoading
);

export const selectError = createSelector(selectState, (state) => state.error);
