import { Well } from '../models';

export const featureKey = 'ct-scan';

export interface State {
    wellId: string;
    well: Well;
    isLoading: boolean;
    error: string;
}

export const initialState: State = {
    wellId: null,
    well: null,
    isLoading: false,
    error: null,
};
