import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WellStoreModule } from '../well/store';
import { CTOverviewStoreModule } from '../ct-overview/store';
import { SlabStoreModule } from '../ct-view-slab/store';
import { HeaderStoreModule } from '../ct-header/store';
import { CircumferenceStoreModule } from '../circumference/store';
import { ThreeDPlugStoreModule } from '../three-d-plug/store';

@NgModule({
    imports: [
        CommonModule,
        WellStoreModule,
        CTOverviewStoreModule,
        SlabStoreModule,
        HeaderStoreModule,
        CircumferenceStoreModule,
        ThreeDPlugStoreModule,
    ],
})
export class CTScanStoreModule {}
