import { createReducer, Action, on } from '@ngrx/store';
import { initialState, State } from './state';
import * as FeatureActions from './actions';

const featureReducer = createReducer(
    initialState,
    on(FeatureActions.loadWell, (state, action) => ({
        ...state,
        wellId: action.wellId,
        isLoading: true,
    })),
    on(FeatureActions.loadWellSuccess, (state, action) => ({
        ...state,
        isLoading: false,
        well: action.well,
    })),
    on(FeatureActions.loadWellFailure, (state, action) => ({
        ...state,
        isLoading: false,
        error: action.error,
    }))
);

export function reducer(state: State | undefined, action: Action): State {
    return featureReducer(state, action);
}
