export class Option {
    bulkMin: number;
    bulkMax: number;
    hasThreeFootView: any;
    threeFootImagesFolder: string;
    circumHeight: number;
    circumWidth: number;
    imageSequenceDigits: number;
    isDiameter6Inch: any;
    isPlugView: any;
    uom: string;
    mtrFtSections: number;
}

export class Measurement {
    axialRulerLength: number;
    axialPixelDiameter: number;
    axialRulerImgSrc: string;

    circumRulerLength: number;
    circumRulerImgSrc: string;

    slabRulerLength: number;
    slabRulerImgSrc: string;

    plugRulerLength: number;
    plugRulerImgSrc: string;
}

// Circumference Image
export class CircImage {
    id: number;
    path: string;
    lbl: string;
    src: string;
    startDepth: string;
    endDepth: number;
    axialFolder: string;
    axialCount: number;
    axialDiameter: number;
    yMulti: number;
    xMulti: number;
    axialImages: AxialImage[];
}

export class TfvImage {
    id: number;
    src: string;
    srcShort: string; // used for calculating which TFVImage to show
}

export class SagImage {
    src: string;
}

// All Ruler Images
export class RulerImage {
    src: string;
}

// Axial Image
export class AxialImage {
    src: string;
}
