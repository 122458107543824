import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CtSharedService } from './ct-shared.service';

@Injectable({ providedIn: 'root' })
export class PathGeneratorService {
    private wellBasePath: string;
    bulkDensityUrl: string;
    wellId: string;
    ctPlugBasePath: string;
    xmlFolder = '';
    logUrl = '';
    imgUrl = environment.apiUrl + 'api/CTScan/GetCTScanImg?path=';
    assetPath = 'core_1/ct_images/';
    assetPathCircum = 'core_1/';
    incPath = '../../assets/ct-resources/';

    constructor(
        private ctSharedService: CtSharedService,
        private http: HttpClient
    ) {}

    async setWellPathInfo(wellId: string) {
        this.wellId = wellId;
        this.logUrl = `${environment.apiUrl}api/CTScan/GetCTScanDatasetDB?wll_id=${wellId}&startDepth=`;
        this.bulkDensityUrl = `${environment.apiUrl}api/CTScan/GetCTBulkDensityDB?wll_id=${wellId}&startDepth=`;
        const url = `${environment.apiUrl}api/CTScan/GetCTWell?wll_id=${wellId}`;
        const jData = await this.http
            .get<{ CMPNY_DIRECTORY: string; WBST_DIRECTORY: string }[]>(url)
            .toPromise();
        this.wellBasePath = `${jData[0].CMPNY_DIRECTORY}/${jData[0].WBST_DIRECTORY}/CT_Scan/`;
        this.ctPlugBasePath = this.wellBasePath;
        this.assetPath = this.wellBasePath + this.assetPath;
        this.assetPathCircum = this.wellBasePath + this.assetPathCircum;
        this.ctSharedService.pushAssetPath(this.assetPath);
    }

    getXMLPath(): string {
        this.xmlFolder = this.xmlFolder || 'core_1/data.xml';
        return `${environment.apiUrl}api/CTScan/GetCTScanConfigsPath?path=${this.wellBasePath}${this.xmlFolder}`;
    }
}
