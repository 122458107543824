import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, featureKey } from './state';

const selectState = createFeatureSelector<State>(featureKey);

export const selectImages = createSelector(
    selectState,
    (state) => state.images
);

export const selectSelectedImage = createSelector(
    selectState,
    (state) => state.selectedImage
);

export const selectIsLoading = createSelector(
    selectState,
    (state) => state.isLoading
);
