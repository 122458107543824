import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Well, ImageMetadata } from '../well/models';
import { SlabImage } from '../models/';

@Injectable({ providedIn: 'root' })
export class SlabImagesService {
    getImagesFromWell(well: Well): SlabImage[] {
        return well.core.images.map((image, id) => ({
            id,
            tfvMainIndex: id,
            sagFolder: image.sagFolder,
            sagCount: image.sagCount,
            axialFolder: image.axialFolder,
            axialCount: image.axialCount,
            axialDiameter: image.axialDiameter,
            startDepth: Number(image.startDepth),
            endDepth: Number(image.endDepth),
            lbl: image.label,
            tfvIndex: image.tfvindex,
            tfvSrc: `${environment.imagesUrl}${well.path}/ct_images/Tubes XZ/${image.tfvsrc}`,
            srcShort: image.tfvsrc,
            yMulti: image.axialCount / well.options.circumHeight,
            slabAxMulti: image.sagCount / 2,
            axialImage: this.setSlabAxImages(
                image,
                well.options.imageSequenceDigits,
                well.path
            ),
            sagImage: this.setSagImages(
                image,
                well.options.imageSequenceDigits,
                well.path
            ),
            src: this.buildSourceForImage(
                image,
                well.options.imageSequenceDigits,
                well.path
            ),
        }));
    }

    // TODO: Refactor this method
    private buildSourceForImage(
        { sagFolder, sagCount, startDepth }: ImageMetadata,
        digits: number,
        wellPath: string
    ): string {
        return `${
            environment.imagesUrl
        }${wellPath}/ct_images/${sagFolder}/${startDepth.replace(
            '.',
            '_'
        )}-${this.leftpad(Math.floor(Number(sagCount) / 2), digits)}.jpg`;
    }

    private setSlabAxImages(
        { startDepth, endDepth, axialCount, axialFolder }: ImageMetadata,
        digits: number,
        wellPath: string
    ): string[] {
        const axialImage: string[] = [];
        const depthRange = Number(endDepth) - Number(startDepth);
        for (let i = 0; i < depthRange; i++) {
            for (let j = 1; j <= Number(axialCount); j++) {
                axialImage.push(
                    `${
                        environment.imagesUrl
                    }${wellPath}/ct_images/${axialFolder}/${startDepth.replace(
                        '.',
                        '_'
                    )}-${this.leftpad(j, digits)}.jpg`
                );
            }
        }

        return axialImage;
    }

    // Slab Sagittals
    private setSagImages(
        { startDepth, endDepth, sagCount, sagFolder }: ImageMetadata,
        digits: number,
        wellPath: string
    ): string[] {
        const sagImage: string[] = [];
        const depthRange = Number(endDepth) - Number(startDepth);
        for (let i = 0; i < depthRange; i++) {
            for (let j = 1; j <= Number(sagCount); j++) {
                sagImage.push(
                    `${
                        environment.imagesUrl
                    }${wellPath}/ct_images/${sagFolder}/${startDepth.replace(
                        '.',
                        '_'
                    )}-${this.leftpad(j, digits)}.jpg`
                );
            }
        }

        return sagImage;
    }

    private leftpad(target: number, totalLength: number): string {
        const imgNo = `000${target}`;
        return imgNo.substr(imgNo.length - totalLength);
    }
}
