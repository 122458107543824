import { createAction, props } from '@ngrx/store';
import { Well } from '../models';

export const loadWell = createAction(
    '[App Component] Load well',
    props<{ wellId: string }>()
);
export const loadWellSuccess = createAction(
    '[App Component] Load well success',
    props<{ well: Well }>()
);
export const loadWellFailure = createAction(
    '[App Component] Load well failure',
    props<{ error: string }>()
);
