import { createSelector } from '@ngrx/store';
import { WellStoreSelectors } from '../well/store';
import { CTOverviewStoreSelectors } from '../ct-overview/store';
import { CircumferenceStoreSelectors } from '../circumference/store';
import { ThreeDPlugStoreSelectors } from '../three-d-plug/store';

export const selectIsLoading = createSelector(
    WellStoreSelectors.selectIsLoading,
    CTOverviewStoreSelectors.selectIsLoading,
    CircumferenceStoreSelectors.selectIsLoading,
    ThreeDPlugStoreSelectors.selectIsLoading,
    (...isLoadings) => isLoadings.find((isLoading) => isLoading)
);

export const selectError = createSelector(
    WellStoreSelectors.selectError,
    (...errors) => errors.find((error) => error)
);
