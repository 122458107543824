import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { featureKey } from './state';
import { reducer } from './reducer';
import { Effects } from './effects';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(featureKey, reducer),
        EffectsModule.forFeature([Effects]),
    ],
})
export class ThreeDPlugStoreModule {}
