import { createReducer, Action, on } from '@ngrx/store';
import { initialState, State } from './state';
import * as FeatureActions from './actions';

const featureReducer = createReducer(
    initialState,
    on(FeatureActions.create3DPlug, (state) => ({
        ...state,
        isLoading: true,
    })),
    on(FeatureActions.create3DPlugSuccess, (state, { threeDPlug }) => ({
        ...state,
        threeDPlug: threeDPlug,
        isLoading: false,
    }))
);

export function reducer(state: State | undefined, action: Action): State {
    return featureReducer(state, action);
}
