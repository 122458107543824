import { createReducer, Action, on } from '@ngrx/store';
import { initialState, State } from './state';
import * as FeatureActions from './actions';

const featureReducer = createReducer<State>(
    initialState,
    on(FeatureActions.loadCircumferenceImages, (state) => ({
        ...state,
        isLoading: true,
    })),
    on(FeatureActions.loadCircumferenceImagesSuccess, (state, { images }) => ({
        ...state,
        isLoading: false,
        circumferenceImages: images,
    }))
);

export function reducer(state: State | undefined, action: Action): State {
    return featureReducer(state, action);
}
