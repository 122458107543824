import { DepthIndication, DepthRange } from '../models';
import { DectLogs } from '../models/dect-logs';

export const featureKey = 'slab';

export interface State {
    plugSelector: boolean;
    logs: DectLogs;
    drawMeasure: boolean;
    depthIndication: DepthIndication;
    depthRange: DepthRange;
    offset: number;
}

export const initialState: State = {
    plugSelector: false,
    logs: null,
    drawMeasure: false,
    depthIndication: { percentage: 0, slabImage: null, depth: 0 },
    depthRange: { start: 0, end: 0 },
    offset: 0,
};
