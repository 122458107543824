import { CircumferenceImage } from '../models';

export const featureKey = 'circumference';

export interface State {
    circumferenceImages: CircumferenceImage[];
    isLoading: boolean;
}

export const initialState: State = {
    circumferenceImages: [],
    isLoading: false,
};
