import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MessageErrorService {
  private errSubject = new Subject<any>();

 sendErrMessage(message: any) {
   this.errSubject.next({ msg: message });
  }

  clearErrMessage() {
    this.errSubject.next();
  }

  getErrMessage(): Observable<any> {
    return this.errSubject.asObservable();
  }
}
