import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CtSharedService {
    private jpegImg = new Subject<string>();
    $jpegImg = this.jpegImg.asObservable();

    public pHeaderHeight: number;
    private headerHeight = new Subject<number>();
    $headerHeight = this.headerHeight.asObservable();

    private tsSlabIdxDep = new BehaviorSubject<number>(0);
    $tsSlabIdxDep = this.tsSlabIdxDep.asObservable();
    public pubTsSlabIdxDep = 0;

    // From Slab index for TFV horizontal line position
    private depthIndex = new BehaviorSubject<number>(-1);
    depthIndex$ = this.depthIndex.asObservable();

    // controls chart view (3ft) scroll and tfv image
    private currentTFVMainIndex = new Subject<number>();
    $currentTFVMainIndex = this.currentTFVMainIndex.asObservable();
    private pubCurrentTFVMainIndex;

    private currentTFVSecIndex = new BehaviorSubject<number>(0);
    $currentTFVSecIndex = this.currentTFVSecIndex.asObservable();
    pubCurrentTFVSecIndex: number;

    // controls chart view (1ft) scroll
    private current1FVIndex = new Subject<number>();
    $current1FVIndex = this.current1FVIndex.asObservable();

    private cur1FCVhartScroll = new BehaviorSubject<number>(0);
    $cur1FCVhartScroll = this.cur1FCVhartScroll.asObservable();
    // trigger slab from TFV button to push scrolltop

    // Label for slab/circumference depth in toolbar
    private depthLabel = new Subject<number>();
    depthLabel$: Observable<number> = this.depthLabel.asObservable();
    public pubDepthLabel;

    // Slab enable measurement
    private SvgSlabEvent = new Subject<boolean>();
    SvgSlabEvent$: Observable<boolean> = this.SvgSlabEvent.asObservable();

    // Circumference enable measurement
    private SvgCircumEvent = new Subject<boolean>();
    SvgCircumEvent$ = this.SvgCircumEvent.asObservable();

    private scrollToParamsIdx = new Subject<number>();
    $scrollToParamsIdx = this.scrollToParamsIdx.asObservable();

    private scrollToDepth = new Subject<{
        depth: number;
        index: number;
    }>();
    scrollToDepth$: Observable<{
        depth: number;
        index: number;
    }> = this.scrollToDepth.asObservable();

    private ctWellInfo = new Subject<any>();
    $ctWellInfo = this.ctWellInfo.asObservable();
    public pubCtWellInfo;

    private ctCorePath = new Subject<string>();
    $ctCorePath = this.ctCorePath.asObservable();

    private ctDepthRange = new Subject<any>();
    ctDepthRange$ = this.ctDepthRange.asObservable();
    public pubCtDepthRange = { min: 0, max: 0 };

    private plugView = new Subject<boolean>();
    $plugView = this.plugView.asObservable();

    private slabHeight = '';

    private SAGPath = new Subject<string>();
    $SAGPath = this.SAGPath.asObservable();

    private assetPath = new Subject<string>();
    $assetPath = this.assetPath.asObservable();

    private ctLogBulk = new Subject<any>();
    $ctLogBulk = this.ctLogBulk.asObservable();

    private ctLogChart = new Subject<any>();
    $ctLogChart = this.ctLogChart.asObservable();

    private ctBLKLogCreateFlag = new Subject<boolean>();
    $ctBLKLogCreateFlag = this.ctBLKLogCreateFlag.asObservable();

    private displayLithoModal = new BehaviorSubject<boolean>(false);
    $displayLithoModal = this.displayLithoModal.asObservable();

    private lithoLegendData = new Subject<any>();
    $lithoLegendData = this.lithoLegendData.asObservable();

    private currNavText = new Subject<string>();
    $currNavText = this.currNavText.asObservable();
    public pubCurrNavText: string;

    private display3DPlug = new Subject<boolean>();
    $display3DPlug = this.display3DPlug.asObservable();

    private directLog = new Subject<any>();
    $directLog = this.directLog.asObservable();

    private directLogDepth = new Subject<any>();
    $directLogDepth = this.directLogDepth.asObservable();

    private localDepthRange = new Subject<any>();
    $localDepthRange = this.localDepthRange.asObservable();

    public pushLithoLegendData(data: any) {
        this.lithoLegendData.next(data);
    }

    public pushDisplayLithoModal(data: boolean) {
        this.displayLithoModal.next(data);
    }

    public pushCtLogChart(data: any) {
        this.ctLogChart.next(data);
    }

    public pushCtLogBulk(data: any) {
        this.ctLogBulk.next(data);
    }

    public getTFVSection(index): Observable<any> {
        return of(this.$currentTFVSecIndex);
    }

    public pushLocalDepthRange(data: any) {
        this.localDepthRange.next(data);
    }

    public pushDirectLogDepth(data: any) {
        this.directLogDepth.next(data);
    }

    public pushDirectLog(data) {
        this.directLog.next(data);
    }

    public pushDisplay3DPlug(data: boolean) {
        this.display3DPlug.next(data);
    }

    // image path from dbQueryService.
    public pushAssetPath(data: string) {
        this.assetPath.next(data);
    }

    public pushNavText(data: string) {
        this.currNavText.next(data);
        this.pubCurrNavText = data;
    }
    public getNavText() {
        return this.pubCurrNavText;
    }

    public pushSAGPath(data: string) {
        this.SAGPath.next(data);
    }

    public pushSlabHeight(data: any) {
        this.slabHeight = data;
    }
    public getSlabHeight() {
        return this.slabHeight;
    }

    public pushPlugView(data: boolean) {
        this.plugView.next(data);
    }

    public pushCtDepthRange(data: any) {
        this.ctDepthRange.next(data);
        this.pubCtDepthRange = data;
    }

    public pushCtCorePath(data: string) {
        this.ctCorePath.next(data);
    }

    public pushCtWellInfo(data: any) {
        this.ctWellInfo.next(data);
        this.pubCtWellInfo = data;
    }

    pushTsSlabIdxDepth(data: number) {
        this.tsSlabIdxDep.next(data);
        this.pubTsSlabIdxDep = data;
    }

    setDepthIndex(index: number) {
        this.depthIndex.next(index);
    }

    // Call Src: ct-view-slab.comp
    pushCurrentTFVMainIndex(data: number) {
        this.currentTFVMainIndex.next(data);
        this.pubCurrentTFVMainIndex = data;
    }

    getCurrentTFVMainIndex() {
        return this.pubCurrentTFVMainIndex;
    }

    pushCurrentTFVSecIndex(data: number) {
        this.currentTFVSecIndex.next(data);
        this.pubCurrentTFVSecIndex = data;
    }

    // for
    push1FVIndex(data: number) {
        this.current1FVIndex.next(data);
    }

    push1FVChartScroll(data: number) {
        this.cur1FCVhartScroll.next(data);
    }

    pushDepthLabel(data: any) {
        this.depthLabel.next(data);
        this.pubDepthLabel = data;
    }
}
