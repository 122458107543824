import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import {
    NavigationActionTiming,
    routerReducer,
    StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../environments/environment';
import { CTScanStoreModule } from '../ct-scan/store';

@NgModule({
    imports: [
        CommonModule,
        CTScanStoreModule,
        StoreModule.forRoot(
            { router: routerReducer },
            {
                runtimeChecks: {
                    strictStateImmutability: true,
                    strictActionImmutability: true,
                },
            }
        ),
        EffectsModule.forRoot([]),
        StoreRouterConnectingModule.forRoot({
            navigationActionTiming: NavigationActionTiming.PostActivation,
        }),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
        }),
    ],
})
export class AppStoreModule {}
