import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { State } from '../../../store/state';
import { WellStoreSelectors } from '../../well/store';
import { CircumferenceImagesService } from '../../services';
import * as FeatureActions from './actions';

@Injectable()
export class Effects {
    constructor(
        private actions$: Actions,
        private store$: Store<State>,
        private service: CircumferenceImagesService
    ) {}

    triggerLoadImages$ = createEffect(() =>
        this.store$.pipe(
            select(WellStoreSelectors.selectWell),
            filter((well) => !!well),
            map((well) => FeatureActions.loadCircumferenceImages({ well }))
        )
    );

    loadCircumferenceImages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FeatureActions.loadCircumferenceImages),
            map(({ well }) =>
                this.service.getCircumferenceImagesFromWell(well)
            ),
            map((images) =>
                FeatureActions.loadCircumferenceImagesSuccess({ images })
            )
        )
    );
}
