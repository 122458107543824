import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../_services/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const cookie = this.authService.getTokenInfo('COOKIE');
        const currentUser = this.authService.getTokenInfo('STORAGE');

        if (cookie && cookie.token) {
            const headers = request.headers
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${cookie.token}`)
                .set('Source', cookie.source);

            const params = request.params
                .append('source', cookie.source);

            const cloneReq = request.clone({ headers, params });

            return next.handle(cloneReq);
        }

        if (currentUser && currentUser.token) {
            const headers = request.headers
                .set('Content-Type', 'application/json')
                .set('Authorization', `Bearer ${currentUser.token}`)
                .set('Source', currentUser.source);

            const params = request.params
                .append('source', currentUser.source);

            const cloneReq = request.clone({ headers, params });

            return next.handle(cloneReq);
        }

        return next.handle(request);
    }
}
