import { MessageType } from './message.enum';

export class User {
  id: number;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  authenticated: boolean;
  claims: string[];
  token: string;
  sessionId: string;
  source: string;
  environment: string;
  messageType: MessageType;
}
