import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
    CircumferenceImageMetadata,
    ImageMetadata,
    ScanDirectory,
    Well,
    WellQuery,
} from '../well/models';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class WellsService {
    constructor(private http: HttpClient) {}

    getWell({ id, folder }: WellQuery): Observable<Well> {
        return this.getWellDirectories(id).pipe(
            switchMap(([directory]) =>
                this.getWellFromDirectory(id, directory, folder)
            )
        );
    }

    private getWellDirectories(wellId: string): Observable<ScanDirectory[]> {
        const params = {
            wll_id: wellId,
        };
        return this.http.get<ScanDirectory[]>(
            `${environment.apiUrl}api/CTScan/GetCTWell`,
            { params }
        );
    }

    private getWellFromDirectory(
        id: string,
        { CMPNY_DIRECTORY, WBST_DIRECTORY }: ScanDirectory,
        folder: string
    ): Observable<Well> {
        const wellPath = `${CMPNY_DIRECTORY}/${WBST_DIRECTORY}/CT_Scan/${folder}`;
        const params = {
            path: `${wellPath}/data.xml`,
        };
        return this.http
            .get<RawWell>(
                `${environment.apiUrl}api/CTScan/GetCTScanConfigsPath`,
                { params }
            )
            .pipe(map((rawWell) => this.convertToWell(id, rawWell, wellPath)));
    }

    private convertToWell(
        id: string,
        rawWell: RawWell,
        wellPath: string
    ): Well {
        return {
            id,
            header: {
                name: rawWell.Info.WellName,
                company: rawWell.Info.CompanyName,
                field: rawWell.Info.FieldName,
                county: rawWell.Info.County,
                state: rawWell.Info.State,
                country: rawWell.Info.Country,
            },
            path: wellPath,
            core: {
                images: rawWell.Core.CoreImgXmls,
                circumferenceImages: rawWell.Core.CoreImgCircums,
            },
            menuOptions: rawWell.MenuOptions.map(({ Url, Label }) => ({
                url: Url,
                label: Label,
            })),
            options: {
                axialPixelDiameter: rawWell.Options.AxialPixelDiameter,
                bulk: {
                    min: rawWell.Options.BulkMin,
                    max: rawWell.Options.BulkMax,
                },
                imageSequenceDigits: rawWell.Options.ImageSequenceDigits,
                circumHeight: rawWell.Options.CircumWidth,
                circumWidth: rawWell.Options.Circum2Width,
                unitOfMeasure: rawWell.Info.Uom,
                ruler: {
                    axial: `/assets/${rawWell.Options.AxialRulerImageSrc}`,
                    axialLength: rawWell.Options.AxialRulerLength,
                    plug: `/assets/${rawWell.Options.PlugRulerImageSrc}`,
                    slab: `/assets/${rawWell.Options.SlabRulerImageSrc}`,
                    slabLength: rawWell.Options.SlabRulerLength,
                    circumference: `/assets/${rawWell.Options.CircumRulerImageSrc}`,
                    circumferenceLength: rawWell.Options.CircumRulerLength,
                },
                sectionIncrement:
                    (rawWell.Info.Uom || '').toLowerCase() === 'm' ? 4 : 3,
                indicatorColor: {
                    axial: {
                        vertical: rawWell.Colors.V_Axial
                            ? rawWell.Colors.V_Axial.replace('0x', '#')
                            : 'red',
                        horizontal: rawWell.Colors.H_Axial
                            ? rawWell.Colors.H_Axial.replace('0x', '#')
                            : 'yellow',
                    },
                    circumference: {
                        horizontal: rawWell.Colors.H_CircumLn
                            ? rawWell.Colors.H_CircumLn.replace('0x', '#')
                            : 'red',
                    },
                },
                fileType: {
                    lithology: rawWell.FileType.Lithology,
                    bulkDensity: rawWell.FileType.BulkDensity,
                    dectChart: rawWell.FileType.DectChart,
                },
            },
            charts: rawWell.LogHeaders.map(({ Name, Min, Max, Group }) => ({
                name: Name,
                min: Min,
                max: Max,
                group: Group,
            })),
        };
    }
}

interface RawWell {
    Core: RawWellCore;
    MenuOptions: MenuOption[];
    Options: RawWellOptions;
    Info: RawWellInfo;
    LogHeaders: RawWellLogsDisplay[];
    Colors: RawWellLineColors;
    FileType: FileType;
}

interface RawWellCore {
    CoreImgXmls: ImageMetadata[];
    CoreImgCircums: CircumferenceImageMetadata[];
}

interface RawWellInfo {
    CompanyName: string;
    WellName: string;
    FieldName: string;
    County: string;
    State: string;
    Country: string;
    Uom: string;
}

interface RawWellOptions {
    AxialPixelDiameter: number;
    BulkMin: number;
    BulkMax: number;
    ImageSequenceDigits: number;
    CircumWidth: number;
    Circum2Width: number;
    AxialRulerImageSrc: string;
    AxialRulerLength: number;
    PlugRulerImageSrc: string;
    SlabRulerImageSrc: string;
    SlabRulerLength: number;
    CircumRulerImageSrc: string;
    CircumRulerLength: number;
}

interface RawWellLogsDisplay {
    Name: string;
    Min: number;
    Max: number;
    Group: number;
}

interface MenuOption {
    Label: string;
    Url: string;
}

interface RawWellLineColors {
    V_Axial: string;
    H_Axial: string;
    H_CircumLn: string;
}

interface FileType {
    Lithology: string;
    BulkDensity: string;
    DectChart: string;
}
