import { createReducer, Action, on } from '@ngrx/store';
import { initialState, State } from './state';
import * as FeatureActions from './actions';

const featureReducer = createReducer(
    initialState,
    on(FeatureActions.loadImages, (state) => ({
        ...state,
        isLoading: true,
    })),
    on(FeatureActions.loadImagesSuccess, (state, { images }) => ({
        ...state,
        images,
        isLoading: false,
    })),
    on(FeatureActions.viewImage, (state, { image }) => ({
        ...state,
        selectedImage: image,
    }))
);

export function reducer(state: State | undefined, action: Action): State {
    return featureReducer(state, action);
}
