import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, combineLatest } from 'rxjs';
import {
    catchError,
    filter,
    map,
    switchMap,
    withLatestFrom,
    startWith,
} from 'rxjs/operators';
import { WellsService } from '../../services';
import {
    HeaderStoreActions,
    HeaderStoreSelectors,
} from '../../ct-header/store';
import { State } from './state';
import { selectQueryParam, selectWellId } from './selectors';
import * as FeatureActions from './actions';

@Injectable()
export class Effects {
    constructor(
        private actions$: Actions,
        private store$: Store<State>,
        private wellsService: WellsService
    ) {}

    triggerLoadWell$ = createEffect(() =>
        combineLatest([
            this.actions$.pipe(
                ofType(HeaderStoreActions.changeSelectedCore),
                startWith('')
            ),
            this.store$.pipe(
                select(selectQueryParam('id')),
                filter((wellId) => !!wellId)
            ),
        ]).pipe(map(([_, wellId]) => FeatureActions.loadWell({ wellId })))
    );

    loadWell$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FeatureActions.loadWell),
            withLatestFrom(
                this.store$.pipe(select(selectWellId)),
                this.store$.pipe(select(HeaderStoreSelectors.selectCoreFolder))
            ),
            switchMap(([_, id, folder]) =>
                this.wellsService.getWell({ id, folder }).pipe(
                    map((well) => FeatureActions.loadWellSuccess({ well })),
                    catchError((error) =>
                        of(FeatureActions.loadWellFailure({ error }))
                    )
                )
            )
        )
    );
}
