import { createAction, props } from '@ngrx/store';
import { Well } from '../../well/models';
import { CircumferenceImage } from '../models';

export const loadCircumferenceImages = createAction(
    '[Circumference Component] Load circumference images',
    props<{ well: Well }>()
);
export const loadCircumferenceImagesSuccess = createAction(
    '[Circumference Component] Load circumference images success',
    props<{ images: CircumferenceImage[] }>()
);
