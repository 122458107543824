import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { ThreeDPlugService } from '../../services';
import * as FeatureActions from './actions';
import { of } from 'rxjs';

@Injectable()
export class Effects {
    constructor(
        private actions$: Actions,
        private service: ThreeDPlugService
    ) {}

    create3DPlug$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FeatureActions.create3DPlug),
            switchMap(({ wellPath, plugDetail }) =>
                this.service.generatePlug(wellPath, plugDetail).pipe(
                    map((threeDPlug) =>
                        FeatureActions.create3DPlugSuccess({ threeDPlug })
                    ),
                    catchError((error) =>
                        of(FeatureActions.create3DPlugFailure({ error }))
                    )
                )
            )
        )
    );
}
