import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Option, Measurement, TfvImage } from '../models/bodyClasses';
import { CtLogBuffer } from '../models/ctLogBuffer';
import { CtSharedService } from './ct-shared.service';
import { PathGeneratorService } from './path-generator.service';

@Injectable({ providedIn: 'root' })
export class DbQueryService implements OnDestroy {
    private ctLogsRBuffer = new CtLogBuffer(this.http);
    private ctBulkDensityRBuffer = new CtLogBuffer(this.http);
    private subscriptions: Subscription[] = [];
    private tfvImage: Array<TfvImage> = [];
    private overViewImages = [];
    private circumImages = []; // circumference
    private slabWidth = 360;
    private tfvImgWidth = 120;
    private tfvImgRatio = 0;
    private xmlData;
    private logIncrement: number;

    ctLogHeaders = []; // Header info for logs.  Name, min, max, group num
    overViewImgHeight = [];
    tsImage = []; // slab
    lithologyImages = [];
    showPlugView = false;
    hideBulk = false;
    tOption = new Option();
    tMeasurement = new Measurement();
    depthRange = {
        min: 0,
        max: 0,
    };
    chartDepthRange = {
        min: null,
        max: null,
    };

    constructor(
        private http: HttpClient,
        private ctSharedService: CtSharedService,
        private route: ActivatedRoute,
        private pathGenerator: PathGeneratorService
    ) {
        this.subscriptions.push(
            this.ctSharedService.$ctCorePath.subscribe((data) => {
                this.pathGenerator.assetPath = data + 'ct_images/';
                this.pathGenerator.assetPathCircum = data;
                this.pathGenerator.xmlFolder = data + 'data.xml';
                this.loadNew();
            }),

            this.ctSharedService.ctDepthRange$.subscribe((data) => {
                this.depthRange = data;
            })
        );

        this.loadNew();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(({ unsubscribe }) => unsubscribe());
    }

    async loadNew() {
        const id = this.route.snapshot.queryParamMap.get('id');
        await this.pathGenerator.setWellPathInfo(id);
        this.ctLogsRBuffer = new CtLogBuffer(this.http);
        const xmlUrl = this.pathGenerator.getXMLPath();
        this.depthRange = { min: null, max: null };
        this.chartDepthRange = { min: null, max: null };
        this.tsImage = [];
        this.circumImages = [];
        this.tfvImage = [];
        this.overViewImages = [];
        this.setUpFromXMLFile(xmlUrl);
        this.getLithoLegend();
    }

    async initialize() {
        await this.setOptions();
        this.setMeasurements();
        this.ctSharedService.pushPlugView(false);
        this.setCircumImages();
        this.hideBulk = false;
        this.setSlabImages();
        this.setTFVImages();
        this.setLithoImages();
    }

    getOptions(): Observable<any> {
        return of(this.tOption);
    }

    getMeasurements(): Observable<any> {
        return of(this.tMeasurement);
    }

    // Circumference
    getCircImages(): Observable<any> {
        return of(this.circumImages);
    }

    getCircAxialCount(): Observable<any> {
        return of(this.circumImages[0].axialCount);
    }

    // Slab
    getSlabImages(): Observable<any> {
        return of(this.tsImage);
    }

    // Axial Circ Images
    getAxialImages(index): Observable<any> {
        return of(this.circumImages[index].AxialImage);
    }

    // Axial Slab Images
    getAxialImagesSlab(index): Observable<any> {
        return of(this.tsImage[index].AxialImage);
    }

    // Three Foot View Images
    getTfvImages(): Observable<any> {
        return of(this.tfvImage);
    }

    // Pixel multipliers
    getAxialYMulti(index): Observable<any> {
        return of(this.circumImages[index].yMulti);
    }

    getAxialSlabYMulti(index): Observable<any> {
        return of(this.tsImage[index].yMulti);
    }

    getSlabAxMulti(index): Observable<any> {
        return of(this.tsImage[index].slabAxMulti);
    }

    getCircHeight(): Observable<any> {
        return of(this.tOption.circumHeight);
    }

    getSlabHeight(): Observable<any> {
        return of(this.tOption.circumHeight);
    }

    getBulkMinMax(): Observable<any> {
        return of({
            min: this.tOption.bulkMin,
            max: this.tOption.bulkMax,
        });
    }

    // Depth range for TFV chart
    getDepthRange(): Observable<any> {
        return of(this.depthRange);
    }

    getSlabWidth(): number {
        return +this.tOption.circumWidth;
    }

    getSlabHeight_4Plug(): number {
        return +this.tOption.circumHeight;
    }

    // Measurement JS variables
    getCircMeasMultiVert(): number {
        return (
            +this.tMeasurement.circumRulerLength / +this.tOption.circumHeight
        );
    }

    getCircMeasMultiHorz(): number {
        return (
            (+this.tMeasurement.axialRulerLength * Math.PI) /
            +this.tOption.circumWidth
        );
    }

    getAxialMeasMulti(): number {
        return (
            +this.tMeasurement.axialRulerLength /
            +this.tMeasurement.axialPixelDiameter
        );
    }

    getSlabMeasMultiVert(): number {
        return +this.tMeasurement.slabRulerLength / +this.tOption.circumHeight;
    }

    getSlabMeasMultiHorz(): number {
        return +this.getAxialMeasMulti();
    }

    getMeasurementTenthsMultiplier(): number {
        if (!this.tOption.uom) {
            return;
        }
        switch (this.tOption.uom.toLowerCase()) {
            case 'feet':
            case 'f':
            case 'ft':
                return 1.2;
        }
        return 1;
    }

    getMeasurementUnitLabel(): string {
        if (!this.tOption.uom) {
            return;
        }
        switch (this.tOption.uom.toLowerCase()) {
            case 'feet':
            case 'f':
            case 'ft':
                return ' in';
        }
        return ' cm';
    }

    getMainUom(): string {
        if (!this.tOption.uom) {
            return;
        }
        switch (this.tOption.uom.toLowerCase()) {
            case 'feet':
            case 'f':
            case 'ft':
                return ' ft';
        }
        return ' m';
    }

    getPlugMeasMulti(): number {
        return +this.tMeasurement.plugRulerLength / +this.tOption.circumHeight;
    }

    getOverViewImages(): Observable<any> {
        return of(this.overViewImages);
    }

    getSlabCount(): Observable<any> {
        return of(this.tsImage.length);
    }

    getAssetPath() {
        return environment.imagesUrl + this.pathGenerator.assetPath;
    }

    private setMtrFtSections = function () {
        if (this.tOption.uom.toString().toLowerCase() === 'm') {
            return 4;
        }
        return 3;
    };

    private async setOptions() {
        this.tOption.bulkMin = this.xmlData.Options.BulkMin;
        this.tOption.bulkMax = this.xmlData.Options.BulkMax;
        this.tOption.hasThreeFootView = this.xmlData.Options.HasThreeFootView;
        this.tOption.threeFootImagesFolder = this.xmlData.Options.threeFootImagesFolder;
        this.tOption.circumHeight = this.xmlData.Options.CircumWidth;
        this.tOption.circumWidth = this.xmlData.Options.Circum2Width;
        this.tOption.imageSequenceDigits = this.xmlData.Options.ImageSequenceDigits;
        this.tOption.isDiameter6Inch = this.xmlData.Options.IsDiameter6Inch;
        this.tOption.isPlugView = this.xmlData.Options.IsPlugView;
        this.tOption.uom = this.xmlData.Info.Uom;
        this.tOption.mtrFtSections = this.setMtrFtSections();
        this.logIncrement = this.tOption.mtrFtSections;
        this.ctSharedService.pushSlabHeight(this.tOption.circumHeight);

        if (this.tOption.isPlugView === true) {
            this.showPlugView = true;
        } else {
            this.showPlugView = false;
        }
        this.tfvImgRatio = this.tfvImgWidth / this.slabWidth;
    }

    private setMeasurements() {
        this.tMeasurement.axialRulerLength = this.xmlData.Options.AxialRulerLength;
        this.tMeasurement.axialPixelDiameter = this.xmlData.Options.AxialPixelDiameter;
        this.tMeasurement.axialRulerImgSrc =
            this.pathGenerator.incPath +
            this.xmlData.Options.AxialRulerImageSrc;
        this.tMeasurement.circumRulerLength = this.xmlData.Options.CircumRulerLength;
        this.tMeasurement.circumRulerImgSrc =
            this.pathGenerator.incPath +
            this.xmlData.Options.CircumRulerImageSrc;
        this.tMeasurement.slabRulerLength = this.xmlData.Options.SlabRulerLength;
        this.tMeasurement.slabRulerImgSrc =
            this.pathGenerator.incPath + this.xmlData.Options.SlabRulerImageSrc;
        this.tMeasurement.plugRulerLength = this.xmlData.Options.PlugRulerLength;
        this.tMeasurement.plugRulerImgSrc =
            this.pathGenerator.incPath + this.xmlData.Options.PlugRulerImageSrc;
    }

    private setYMultiplierImg = function (axialCount) {
        return axialCount / this.tOption.circumHeight;
    };

    private setCircumImages() {
        this.circumImages = [];
        const images = this.xmlData.Core.CoreImgCircums;
        for (let id = 0; id < images.length; id++) {
            const image = {
                id,
                src:
                    environment.imagesUrl +
                    this.pathGenerator.assetPathCircum +
                    images[id].circum,
                lbl: images[id].label,
                startDepth: images[id].startDepth,
                endDepth: images[id].endDepth,
                axialFolder: images[id].axialFolder,
                axialCount: images[id].axialCount,
                axialDiameter: images[id].axialDiameter,
                yMulti: this.setYMultiplierImg(images[id].axialCount),
                xMulti: 0,
                AxialImage: [],
            };
            this.setAxImages(image);
            this.circumImages.push(image);
        }
    }

    private getHeight(url: string) {
        return new Promise(function (resolve) {
            let img = new Image();
            img.src = url;
            img.onload = function () {
                resolve(img.height);
                img = null;
            };
        });
    }

    private setSlabImages() {
        this.overViewImages = [];
        const slabImages = this.xmlData.Core.CoreImgXmls;
        const slabImgCount = slabImages.length;
        const tfvSrcBasePath = `${environment.imagesUrl}${this.pathGenerator.assetPath}${this.tOption.threeFootImagesFolder}`;
        for (let i = 0; i < slabImgCount; i++) {
            const attr = {
                id: i,
                sagFolder: slabImages[i].sagFolder,
                sagCount: slabImages[i].sagCount,
                axialFolder: slabImages[i].axialFolder,
                axialCount: slabImages[i].axialCount,
                axialDiameter: slabImages[i].axialDiameter,
                startDepth: slabImages[i].startDepth,
                endDepth: slabImages[i].endDepth,
                lbl: slabImages[i].label,
                tfvIndex: slabImages[i].tfvindex,
                tfvSrc: `${tfvSrcBasePath}/${slabImages[i].tfvsrc}`,
                srcShort: slabImages[i].tfvsrc,
                tfvMainIndex: i,
                yMulti: 0,
                slabAxMulti: 0,
                imgHeight: 0,
                AxialImage: [],
                SagImage: [],
            };

            // set depthRange properties for use in TFV Grain Density Chart
            if (
                attr.startDepth < this.depthRange.min ||
                this.depthRange.min == null
            ) {
                this.depthRange.min = attr.startDepth;
            }

            if (
                attr.endDepth > this.depthRange.max ||
                this.depthRange.max == null
            ) {
                this.depthRange.max = attr.endDepth;
            }

            attr.yMulti = this.setYMultiplierImg(attr.axialCount);
            attr.slabAxMulti = attr.sagCount / 360;
            this.setSlabAxImages(attr);
            this.setSagImages(attr);
            this.tsImage.push(attr);
        }

        this.chartDepthRange = this.depthRange;
    }

    private setPlugImages() {
        this.hideBulk = true;
        const plugImages = this.xmlData.Core.CoreImgXmls;
        for (let i = 0; i < plugImages.length; i++) {
            const attr = {
                id: i,
                sagFolder: plugImages[i].sagFolder,
                sagCount: plugImages[i].sagCount,
                axialFolder: plugImages[i].axialFolder,
                axialCount: plugImages[i].axialCount,
                axialDiameter: plugImages[i].axialDiameter,
                startDepth: plugImages[i].startDepth,
                endDepth: plugImages[i].endDepth,
                lbl: plugImages[i].label,
                yMulti: 0,
                slabAxMulti: 0,
                AxialImage: [],
                SagImage: [],
            };

            // set depthRange properties for use in TFV Grain Density Chart
            if (
                attr.startDepth < this.depthRange.min ||
                this.depthRange.min == null
            ) {
                this.depthRange.min = attr.startDepth;
            }

            if (
                attr.endDepth > this.depthRange.max ||
                this.depthRange.max == null
            ) {
                this.depthRange.max = attr.endDepth;
            }

            attr.yMulti = this.setYMultiplierImg(attr.axialCount);
            attr.slabAxMulti = attr.sagCount / 360;
            this.setSlabAxImages(attr);
            this.setSagImages(attr);
            this.tsImage.push(attr);
        }
    }

    // Circumference Axials
    private setAxImages(attr) {
        const depthRange = attr.endDepth - attr.startDepth;
        const digits = this.tOption.imageSequenceDigits;
        const depthTracker = attr.startDepth.toString().replace('.', '_');
        for (let i = 0; i < depthRange; i++) {
            for (let j = 1; j <= attr.axialCount; j++) {
                let imgNo = '000' + j;
                imgNo = imgNo.substr(imgNo.length - digits);
                attr.AxialImage.push(
                    `${environment.imagesUrl}${this.pathGenerator.assetPath}${attr.axialFolder}/${depthTracker}-${imgNo}.jpg`
                );
            }
        }
    }

    // Slab Axials
    private setSlabAxImages(attr) {
        const depthRange = attr.endDepth - attr.startDepth;
        const digits = this.tOption.imageSequenceDigits;
        const depthTracker = attr.startDepth.toString().replace('.', '_');
        for (let i = 0; i < depthRange; i++) {
            for (let j = 1; j <= attr.axialCount; j++) {
                let imgNo = '000' + j;
                imgNo = imgNo.substr(imgNo.length - digits);
                attr.AxialImage.push(
                    `${environment.imagesUrl}${this.pathGenerator.assetPath}${attr.axialFolder}/${depthTracker}-${imgNo}.jpg`
                );
            }
        }
    }

    // Slab Sagittals
    private setSagImages(attr) {
        const depthRange = attr.endDepth - attr.startDepth;
        const digits = this.tOption.imageSequenceDigits;
        const depthTracker = attr.startDepth.toString().replace('.', '_');
        for (let i = 0; i < depthRange; i++) {
            for (let j = 1; j <= attr.sagCount; j++) {
                let imgNo = '000' + j;
                imgNo = imgNo.substr(imgNo.length - digits);
                attr.SagImage.push(
                    `${environment.imagesUrl}${this.pathGenerator.assetPath}${attr.sagFolder}/${depthTracker}-${imgNo}.jpg`
                );
            }
        }
        // build overViewImageDisplay
        let overViewImgNo = '000' + Math.floor(attr.sagCount / 2).toString();
        overViewImgNo = overViewImgNo.substr(overViewImgNo.length - digits);
        const img = `${environment.imagesUrl}${this.pathGenerator.assetPath}${attr.sagFolder}/${depthTracker}-${overViewImgNo}.jpg`;
        this.getHeight(img).then((data) => {
            attr.imgHeight = data;
            this.overViewImgHeight[attr.id] = attr.imgHeight * this.tfvImgRatio;
            // set Slab image height once instead of for every slab saggital
            this.tsImage[attr.id].imgHeight = data;
        });
        this.buildOverView(attr, img);
    }

    private buildOverView(attr, img: string) {
        if (attr.tfvSrc) {
            let tfvSrc = attr.tfvSrc;
            tfvSrc = tfvSrc.substring(tfvSrc.lastIndexOf('/') + 1).slice(0, -4);

            if (!tfvSrc.match(/[a-z]/i)) {
                this.getHeight(img).then((height) => {
                    attr.imgHeight = height;
                });
                this.overViewImages.push({
                    src: img,
                    startDepth: Number(attr.startDepth),
                    id: attr.id,
                    imgHeight: attr.imgHeight,
                });
            }
        } else {
            this.overViewImages.push({
                src: img,
                startDepth: Number(attr.startDepth),
                id: attr.id,
                imgHeight: attr.imgHeight,
            });
        }
    }

    // Three Foot View Images
    private setTFVImages() {
        if (this.tOption.hasThreeFootView) {
            const uniqueImgs = [];
            let id = 0;
            // put only unique TVF images
            this.tsImage.forEach((item) => {
                if (uniqueImgs.indexOf(item.tfvSrc) === -1) {
                    uniqueImgs.push(item.tfvSrc);
                    this.tfvImage.push({
                        id: id++,
                        src: item.tfvSrc,
                        srcShort: item.srcShort,
                    });
                }
            });
        }
    }

    private setLithoImages() {
        this.lithologyImages = [];
        let startDepth = Number(this.depthRange.min);
        let endDepth = startDepth + this.logIncrement;
        let id = 0;
        const maxDepthRange = Number(this.depthRange.max);
        while (startDepth < maxDepthRange) {
            this.lithologyImages.push({
                id,
                startDepth,
                endDepth,
                src: `${environment.imagesUrl}${this.pathGenerator.assetPath}LithoStrips/${startDepth}.00.jpg`,
            });

            id++;
            startDepth += this.logIncrement;
            endDepth += this.logIncrement;
        }
    }

    public getLogs() {
        this.ctLogsRBuffer.initialize(
            20,
            this.depthRange.min,
            this.depthRange.max,
            this.depthRange.min,
            this.logIncrement,
            this.pathGenerator.logUrl
        );
        this.ctLogsRBuffer.setPointer(0);
    }

    private loadBulkDensity() {
        this.ctBulkDensityRBuffer.initialize(
            20,
            this.depthRange.min,
            this.depthRange.max,
            this.depthRange.min,
            this.logIncrement,
            this.pathGenerator.bulkDensityUrl
        );
        this.ctBulkDensityRBuffer.setPointer(0);
    }

    private setUpFromXMLFile(xmlUrl: string) {
        this.http.get(xmlUrl).subscribe((xml) => {
            const json = JSON.parse(JSON.stringify(xml));
            this.ctSharedService.pushCtWellInfo(json.Info);
            this.ctLogHeaders = json.LogHeaders;
            this.xmlData = xml;
            this.initialize();
        });
    }

    private openBuffer(bufferType) {
        return bufferType === 'LOGS'
            ? this.ctLogsRBuffer
            : this.ctBulkDensityRBuffer;
    }

    closeBuffer(bufferType, buffer) {
        bufferType === 'LOGS'
            ? (this.ctLogsRBuffer = buffer)
            : (this.ctBulkDensityRBuffer = buffer);
    }

    async getChartDataScroll(startDepth: number, bufferType: string) {
        const buffer = this.openBuffer(bufferType);
        const ctLogs = await buffer.change(startDepth).map((e) => [...e]);
        this.closeBuffer(bufferType, buffer);
        return ctLogs;
    }

    public normalizeDepth(startDepth: number): number {
        const minDiff = startDepth - this.depthRange.min;
        const modDiff = minDiff % this.logIncrement;
        if (modDiff > 0) {
            startDepth -= modDiff;
        }

        return startDepth;
    }

    public async loadDirectSlabChart(startDepth: number) {
        const normalizedDepth = this.normalizeDepth(startDepth);
        const endDepth = normalizedDepth + this.logIncrement;
        const logChartdata = await this.http
            .get<number[][]>(
                `${this.pathGenerator.logUrl}${normalizedDepth}&endDepth=${endDepth}`
            )
            .toPromise();
        this.ctSharedService.pushCtLogChart(logChartdata[0]);
        this.ctSharedService.pushDirectLogDepth(normalizedDepth);
        await this.ctLogsRBuffer.loadNewAsc(5, Number(normalizedDepth));
        await this.ctLogsRBuffer.loadNewDesc(
            14,
            Number(normalizedDepth - this.logIncrement)
        );
        this.ctLogsRBuffer.setPointer(10);
        this.ctLogsRBuffer.setDepth(normalizedDepth);

        const blkchartdata = await this.http
            .get<number[][][]>(
                `${this.pathGenerator.bulkDensityUrl}${normalizedDepth}&endDepth=${endDepth}`
            )
            .toPromise();
        this.ctSharedService.pushCtLogBulk(blkchartdata[0]);
        this.ctSharedService.pushDirectLogDepth(normalizedDepth);
        await this.ctBulkDensityRBuffer.loadNewAsc(5, Number(normalizedDepth));
        await this.ctBulkDensityRBuffer.loadNewDesc(
            14,
            Number(normalizedDepth - this.logIncrement)
        );
        this.ctBulkDensityRBuffer.setPointer(10);
        this.ctBulkDensityRBuffer.setDepth(normalizedDepth);
    }

    getLithoLegend() {
        const lithoImg = [
            '../assets/ct-resources/lithoIMG/Argillaceous Sandstone.bmp',
            '../assets/ct-resources/lithoIMG/Calcareous_Cemented_Zone.bmp',
            '../assets/ct-resources/lithoIMG/Calcareous_Shale.bmp',
            '../assets/ct-resources/lithoIMG/Heavy_Mineral_Cementation.bmp',
            '../assets/ct-resources/lithoIMG/Rubble_Fracture_Plug.png',
            '../assets/ct-resources/lithoIMG/Sandstone.bmp',
            '../assets/ct-resources/lithoIMG/Silty_Shale.bmp',
            '../assets/ct-resources/lithoIMG/Silty_Shale_Heavy_Mineral_Cementation.bmp',
            '../assets/ct-resources/lithoIMG/Slightly_Calcareous_Shale.bmp',
            '../assets/ct-resources/lithoIMG/Slightly_Argillaceous_Sandstone.png',
        ];
        const lithoLabel = [
            'Argillaceous Sandstone',
            'Calcareous Cemented Zone',
            'Calcareous Shale',
            'Heavy Mineral Cementation',
            'Rubble Fracture Plug',
            'Sandstone',
            'Silty Shale',
            'Silty Shale Heavy Mineral Cementation',
            'Slightly Argillaceous Sandstone',
            'Slightly Calcareous Shale',
        ];
        const litho = [lithoImg, lithoLabel];
        this.ctSharedService.pushLithoLegendData(litho);
    }
}
