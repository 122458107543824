import { RouteModel } from '../_models';

export class Helper {
    static formatUWI(uwi): string {
        try {
            const formated =
                uwi.substring(0, 2) +
                '-' +
                uwi.substring(2, 5) +
                '-' +
                uwi.substring(5, 10) +
                '-' +
                uwi.substring(10);
            return formated;
        } catch (e) {
            return '';
        }
    }

    static isColorCoded(name: string): boolean {
        if (!this.hasData(name)) {
            return false;
        }

        const companyNames = ['CHEVRON'];
        for (let i = 0; i < companyNames.length; i++) {
            if (name.toUpperCase().indexOf(companyNames[i]) > -1) {
                return true;
            }
        }
        return false;
    }

    static isPositive(value) {
        return value > 0;
    }

    static toString(obj): string {
        try {
            return obj.toString();
        } catch (e) {
            return '0';
        }
    }
    static isArray(array): boolean {
        try {
            const isArrayWithLength = Boolean(
                Array.isArray(array) && array.length
            );
            return true;
        } catch (e) {
            return false;
        }
    }

    static isEmpty(obj): boolean {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }

        return true;
    }

    static isDate(date): boolean {
        return (
            date &&
            Object.prototype.toString.call(date) === '[object Date]' &&
            !isNaN(date)
        );
    }

    static hasData(value): boolean {
        const result = !(value === undefined || value == null || value === '');
        return result;
    }

    static getSampleID(plugCircle) {}

    static getQueryString(key: string, url: string) {
        const vars = [];
        let hash;
        const hashes = url.slice(url.indexOf('?') + 1).split('&');
        for (let i = 0; i < hashes.length; i++) {
            hash = hashes[i].split('=');
            vars.push(hash[0]);
            vars[hash[0]] = hash[1];
        }
        return vars[key];
    }

    static getRouteModel(url): RouteModel {
        const urlParts = url.split('?');
        const urlPath =
            urlParts !== null && urlParts[0] !== null ? urlParts[0] : '';
        const params =
            urlParts !== null && urlParts[1] !== null ? urlParts[1] : '';
        const paramList = this.getParams(url);

        return new RouteModel(urlPath, paramList);
    }

    static getParams(url): string[] {
        const re = /[?&]([^=#&]+)=([^&#]*)/g;
        let match;
        let isMatch = true;
        const matches = [];
        while (isMatch) {
            match = re.exec(url);
            if (match !== null) {
                matches[decodeURIComponent(match[1])] = decodeURIComponent(
                    match[2]
                );
                if (match.index === re.lastIndex) {
                    re.lastIndex++;
                }
            } else {
                isMatch = false;
            }
        }

        return matches;
    }

    static dynamicSort(property) {
        let sortOrder = 1;

        if (property[0] === '-') {
            sortOrder = -1;
            property = property.substr(1);
        }

        return function (a, b) {
            const result =
                a[property] < b[property]
                    ? -1
                    : a[property] > b[property]
                    ? 1
                    : 0;
            return result * sortOrder;
        };
    }

    static filterByProperty(propertyName, propertyValue, collection): any[] {
        const newCollection = [];
        if (collection == null) {
            return newCollection;
        }

        for (let i = 0; i < collection.length; i++) {
            if (collection[i][propertyName] === propertyValue) {
                newCollection.push(collection[i]);
            }
        }

        return newCollection;
    }

    static fuzzyFilterByProperty(
        propertyName,
        propertyValue,
        collection,
        range
    ): any[] {
        const newCollection = [];
        if (collection == null) {
            return newCollection;
        }

        for (let i = 0; i < collection.length; i++) {
            if (
                collection[i][propertyName] >= propertyValue - range &&
                collection[i][propertyName] <= propertyValue + range
            ) {
                newCollection.push(collection[i]);
            }
        }

        return newCollection;
    }
}
