import { createAction, props } from '@ngrx/store';
import { PlugDetail } from '../models';

export const create3DPlug = createAction(
    '[ThreeD Plug Component] Create ThreeD Plug',
    props<{ wellPath: string; plugDetail: PlugDetail }>()
);
export const create3DPlugSuccess = createAction(
    '[ThreeD Plug Component] Create ThreeD Plug success',
    props<{ threeDPlug: string }>()
);
export const create3DPlugFailure = createAction(
    '[ThreeD Plug Component] Create ThreeD Plug failure',
    props<{ error: string }>()
);
