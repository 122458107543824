import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, featureKey } from './state';

const selectState = createFeatureSelector<State>(featureKey);

export const selectCoreLabel = createSelector(
    selectState,
    (state) => state.core.label
);

export const selectCoreFolder = createSelector(
    selectState,
    (state) => state.core.folder
);
