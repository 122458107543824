import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PlugDetail } from '../three-d-plug/models';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class ThreeDPlugService {
    constructor(private http: HttpClient) {}

    generatePlug(wellPath: string, plug: PlugDetail): Observable<string> {
        const params = `${wellPath}\\ct_images\\${plug.sagPath}\\${plug.startDepth}-%04d.jpg ${plug.plugRect.right} ${plug.plugRect.bottom} ${plug.plugRect.left} ${plug.plugRect.top} ${plug.startImageZIndex} 360`;
        return this.get3DPlug(encodeURI(params));
    }

    private get3DPlug(params: string) {
        const headers = new HttpHeaders({
            'Content-Type': 'image/png',
            Accept: 'image/png',
        });

        return this.http.get<string>(
            `${environment.apiUrl}api/CTScan/Gen3dPlug?param=${params}`,
            { headers }
        );
    }
}
