import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, featureKey } from './state';

const selectState = createFeatureSelector<State>(featureKey);

export const selectCTLogs = createSelector(selectState, (state) => state.logs);

export const selectDepthIndication = createSelector(
    selectState,
    (state) => state.depthIndication
);

export const selectDepthRange = createSelector(
    selectState,
    (state) => state.depthRange
);

export const selectEndDepthRange = createSelector(
    selectState,
    (state) => state.depthRange
);

export const selectIndicatorYOffset = createSelector(
    selectState,
    (state) => state.offset
);
