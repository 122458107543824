import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Injector } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MaterialModule } from './material';
import { MatDialogModule} from '@angular/material/dialog';
import { AppComponent } from './app.component';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { HomeComponent } from './home/home.component';
import { SiteNavComponent } from './_site-nav/site-nav.component';
import { AppRoutingModule } from './app.routing';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AppStoreModule } from './store';
import { GeneralDialogComponent } from './app-common/general-dialog/general-dialog.component';
import { PlotlyViaCDNModule } from 'angular-plotly.js';

PlotlyViaCDNModule.setPlotlyVersion('1.40.0');
PlotlyViaCDNModule.setPlotlyBundle('basic');
export let InjectorInstance: Injector;

@NgModule({
    declarations: [AppComponent, HomeComponent, SiteNavComponent, GeneralDialogComponent],
    imports: [
        BrowserModule,
        MatTableModule,
        MatSortModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        DragDropModule,
        MaterialModule,
        MatDialogModule,
        HttpClientModule,
        AppRoutingModule,
        ScrollingModule,
        AppStoreModule,
        PlotlyViaCDNModule,
        ServiceWorkerModule.register('ngsw-worker.js'),
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        Title,
        HttpClientModule,
    ],
    bootstrap: [AppComponent],
    entryComponents: [GeneralDialogComponent],
})
export class AppModule {
    constructor(private injector: Injector) {
        InjectorInstance = this.injector;
    }
}
