export const featureKey = 'threeDPlug';

export interface State {
    threeDPlug: string;
    isLoading: boolean;
}

export const initialState: State = {
    threeDPlug: '',
    isLoading: false,
};
