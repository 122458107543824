import { createAction, props } from '@ngrx/store';
import { SlabImage } from '../../models';
import { Well } from '../../well/models';

export const loadImages = createAction(
    '[CT Overview Component] Load images',
    props<{ well: Well }>()
);
export const loadImagesSuccess = createAction(
    '[CT Overview Component] Load images success',
    props<{ images: SlabImage[] }>()
);

export const viewImage = createAction(
    '[CT Overview Component] View image',
    props<{ image: SlabImage }>()
);
