import { Core } from '../../models';

export const featureKey = 'header';

export interface State {
    core: Core;
}

export const initialState: State = {
    core: { folder: 'core_1', label: 'Core 1' },
};
