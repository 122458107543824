import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { User } from '../_models';
import { MessageService } from './message.service';
import { environment } from '../../environments/environment';
import { MessageType } from '../_models/message.enum';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    url: string;
    _apiURI: string = environment.apiUrl;
    _environment: string = environment.environment;
    _token: string;

    constructor(
        private http: HttpClient,
        private messageService: MessageService
    ) {}

    getTokenInfo(tokenType: string): User {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        let cookie = document.cookie;
        let userInfo = new User();

        if (tokenType === 'COOKIE') {
            if (cookie) {
                switch (this._environment) {
                    case 'PROD':
                        this._token = this.getCookie('PJWT');
                        break;
                    case 'TEST':
                        this._token = this.getCookie('TJWT');
                        break;
                    case 'DEV':
                        this._token = this.getCookie('DJWT');
                        break;
                }

                if (this._token) {
                    userInfo.username = '';
                    userInfo.authenticated = true;
                    userInfo.token = this._token;
                    userInfo.source = 'MVC';
                    userInfo.environment = this._environment;
                    return userInfo;
                }
            }
        }

        if (tokenType === 'STORAGE') {
            if (currentUser && currentUser.token) {
                userInfo.username = '';
                userInfo.authenticated = true;
                userInfo.token = currentUser.token;
                userInfo.source = 'RapidWeb';
                userInfo.environment = this._environment;
                return userInfo;
            }
        }
        return null;
    }

    getEnvironment(propertyName, propertyValue, collection): string {
        let i = 0;
        const len = collection.length;
        for (; i < len; i++) {
            if (collection[i][propertyName] === propertyValue) {
                return collection[i].Value;
            }
        }
        return null;
    }

    getJwt() {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        let cookie = document.cookie;

        if (cookie) {
            let token = this.getCookie('JWT');

            if (token) {
                return token;
            }
        }

        if (currentUser && currentUser.token) {
            return currentUser.token;
        }
        return null;
    }

    getCookie(name) {
        let re = new RegExp(name + '=([^;&]+)');
        let value = re.exec(document.cookie);
        return value != null ? unescape(value[1]) : null;
    }

    getApiURI() {
        return this._apiURI;
    }

    getUserInfo() {
        let user = localStorage.getItem('currentUser');
        return user;
    }

    getSource() {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        let cookie = document.cookie;

        if (cookie) {
            let source = this.getCookie('Source');

            if (source) {
                return source;
            }
        }

        if (currentUser && currentUser.source) {
            return currentUser.source;
        }
        return null;
    }

    login(username: string, password: string) {
        let url = this._apiURI + 'api/auth/token';
        return this.http
            .post<any>(url, { username: username, password: password })
            .pipe(
                map((res: any) => {
                    // login successful if there's a jwt token in the response
                    if (res && res.access_token) {
                        let currentUser = new User();
                        currentUser.username = username.toUpperCase();
                        currentUser.authenticated = true;
                        currentUser.token = res.access_token;
                        currentUser.claims = res.claims;
                        currentUser.messageType = MessageType.Authentication;
                        currentUser.source = 'RapidWeb';
                        // store username and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem(
                            'currentUser',
                            JSON.stringify(currentUser)
                        );
                        this.messageService.sendMessage(currentUser);
                    }
                })
            );
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        let cookie = document.cookie;

        if (cookie) {
            this.deleteCookie('UserSettings');
        }
    }

    deleteCookie(name: string) {
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
}
