import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Well, CircumferenceImageMetadata } from '../well/models';
import { CircumferenceImage } from '../circumference/models';

@Injectable({ providedIn: 'root' })
export class CircumferenceImagesService {
    getCircumferenceImagesFromWell(well: Well): CircumferenceImage[] {
        return well.core.circumferenceImages.map((image, id) => ({
            id,
            label: image.label,
            startDepth: Number(image.startDepth),
            endDepth: Number(image.endDepth),
            axialFolder: image.axialFolder,
            axialCount: Number(image.axialCount),
            axialDiameter: Number(image.axialDiameter),
            xMulti: 0,
            yMulti: this.getYMultipler(image, well),
            axialImages: this.getAxialImages(image, well),
            source: this.getSource(image.circum, well.path),
        }));
    }

    private getYMultipler(
        image: CircumferenceImageMetadata,
        well: Well
    ): number {
        return Number(image.axialCount) / well.options.circumHeight;
    }

    private getAxialImages(
        image: CircumferenceImageMetadata,
        well: Well
    ): string[] {
        const depthRange = Number(image.endDepth) - Number(image.startDepth);
        const digits = well.options.imageSequenceDigits;
        const depth = image.startDepth.toString().replace('.', '_');
        const axialImages: string[] = [];
        for (let i = 0; i < depthRange; i++) {
            for (let j = 1; j <= Number(image.axialCount); j++) {
                let imgNo = `000${j}`;
                imgNo = imgNo.substr(imgNo.length - digits);
                const rootDirectory = `${environment.imagesUrl}${well.path}/ct_images`;
                axialImages.push(
                    `${rootDirectory}/${image.axialFolder}/${depth}-${imgNo}.jpg`
                );
            }
        }

        return axialImages.sort();
    }

    private getSource(circumferencePath: string, wellPath: string): string {
        return `${environment.imagesUrl}${wellPath}/${circumferencePath}`;
    }
}
