import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { featureKey } from './state';
import { reducer } from './reducer';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(featureKey, reducer)],
})
export class HeaderStoreModule {}
