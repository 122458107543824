import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DepthRange } from '../ct-view-slab/models';
import { SlabImage } from '../models';
import { BulkDensityLog } from '../ct-view-slab/models/bulk-density-log';
import { map } from 'rxjs/operators';
import { ChartsLog } from '../ct-view-slab/models/charts-log';

@Injectable({ providedIn: 'root' })
export class CTLogService {
    constructor(private http: HttpClient) {}

    private bulkDensityUrl = `${environment.apiUrl}api/CTScan/GetCTBulkDensityDB`;
    private chartsUrl = `${environment.apiUrl}api/CTScan/GetCTScanDatasetDB`;

    getBulkDensityLogs(
        wellId: string,
        depthRange: DepthRange
    ): Observable<BulkDensityLog> {
        const params = {
            wll_id: `${wellId}`,
            startDepth: `${depthRange.start}`,
            endDepth: `${depthRange.end}`,
        };

        return this.http
            .get<number[][][]>(this.bulkDensityUrl, {
                params,
            })
            .pipe(map(([logs]) => ({ xValues: logs[1], yValues: logs[0] })));
    }

    getChartLogs(
        wellId: string,
        depthRange: DepthRange
    ): Observable<ChartsLog[]> {
        const params = {
            wll_id: `${wellId}`,
            startDepth: `${depthRange.start}`,
            endDepth: `${depthRange.end}`,
        };

        return this.http.get<ChartsLog[]>(this.chartsUrl, {
            params,
        });
    }

    setDepthRange(
        images: SlabImage[],
        image: SlabImage
    ): Observable<DepthRange> {
        const imagesLength = images.length;
        let startDepth = 0;
        let endDepth = 0;

        if (image && image.id === 0) {
            startDepth = image.startDepth;
            endDepth = images[image.id + 2].endDepth;
        } else if (image && image.id >= imagesLength - 2) {
            startDepth = images[imagesLength - 3].startDepth;
            endDepth = images[imagesLength - 1].endDepth;
        } else {
            startDepth = images[image.id - 1].startDepth;
            endDepth = images[image.id + 2].endDepth;
        }

        return new Observable<DepthRange>((o) =>
            o.next({
                start: startDepth,
                end: endDepth,
            })
        );
    }
}
