import { SlabImage } from '../../models';

export const featureKey = 'ct-overview';

export interface State {
    images: SlabImage[];
    selectedImage: SlabImage;
    isLoading: boolean;
}

export const initialState: State = {
    images: [],
    selectedImage: null,
    isLoading: false,
};
