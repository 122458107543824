import { createAction, props } from '@ngrx/store';
import { DepthIndication, DepthRange } from '../models';
import { DectLogs } from '../models/dect-logs';

export const movePlugSelector = createAction(
    '[Slab View Component] Move circular plug selector',
    props<{ plugPosition: Number[] }>()
);
export const changeDepthIndication = createAction(
    '[Slab View Component] Change depth indication',
    props<{ depthIndication: DepthIndication }>()
);
export const changeDepthRange = createAction(
    '[Slab View Component] Change depth range',
    props<{ depthRange: DepthRange }>()
);
export const changeDepthRangeSuccess = createAction(
    '[Slab View Component] Change depth range success',
    props<{ depthRange: DepthRange }>()
);

export const loadCTLogsSuccess = createAction(
    '[Slab View Component] Load CT logs success',
    props<{ logs: DectLogs }>()
);
export const loadCTLogsFailure = createAction(
    '[Slab View Component] Load CT logs failure',
    props<{ error: string }>()
);

export const changeIndicatorYOffset = createAction(
    '[Slab View Component] Change indicator y offset',
    props<{ offset: number }>()
);
