import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { of, combineLatest } from 'rxjs';
import * as FeatureActions from './actions';
import { CTLogService } from '../../services';
import { Store, select } from '@ngrx/store';
import { State } from '../../well/store/state';
import { selectWellId } from '../../well/store/selectors';
import { selectImages } from '../../ct-overview/store/selectors';
import { selectDepthIndication } from './selectors';

@Injectable()
export class Effects {
    constructor(
        private actions$: Actions,
        private store$: Store<State>,
        private service: CTLogService
    ) {}

    loadCTLogs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FeatureActions.changeDepthRangeSuccess),
            withLatestFrom(this.store$.pipe(select(selectWellId))),
            switchMap(([{ depthRange }, wellId]) =>
                combineLatest([
                    this.service.getChartLogs(wellId, depthRange),
                    this.service.getBulkDensityLogs(wellId, depthRange),
                ]).pipe(
                    map(([chartsLog, bulkDensityLog]) =>
                        FeatureActions.loadCTLogsSuccess({
                            logs: {
                                chartsLog: chartsLog,
                                bulkDensityLog: bulkDensityLog,
                            },
                        })
                    ),
                    catchError((error) =>
                        of(FeatureActions.loadCTLogsFailure({ error }))
                    )
                )
            )
        )
    );

    changeDepthRange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FeatureActions.changeDepthRange),
            withLatestFrom(
                this.store$.pipe(select(selectImages)),
                this.store$.pipe(select(selectDepthIndication))
            ),
            switchMap(([action, images, depthIndication]) =>
                this.service
                    .setDepthRange(images, depthIndication.slabImage)
                    .pipe(
                        map((depthRange) =>
                            FeatureActions.changeDepthRangeSuccess({
                                depthRange,
                            })
                        )
                    )
            )
        )
    );
}
