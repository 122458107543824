import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CtLogBuffer {
    private pointer = 0;
    private buffer = [];
    private minDepth;
    private maxDepth;
    private depth;
    private nextDepth = -1;
    private mtrFtSections;
    private logIncrement;
    private loadPointHigh;
    private loadPointLow;
    private loadStep;
    private length;
    private logFunction;
    private logUrl: any;

    constructor(private http: HttpClient) {}

    initialize(
        llength?: any,
        minDepthB?: any,
        maxDepthB?: any,
        currentDepth?: any,
        mtrFtSec?: any,
        passedPartialApiUrl?: any
    ) {
        this.minDepth = minDepthB;
        this.maxDepth = maxDepthB;
        this.depth = currentDepth;
        this.mtrFtSections = mtrFtSec;
        this.logIncrement = this.mtrFtSections;
        this.loadPointHigh = 15;
        this.loadPointLow = 5;
        this.loadStep = 10;
        this.length = llength;
        this.logUrl = passedPartialApiUrl;
        this.logFunction = this.getLogDataUBufferBlock;
    }

    getItem(key) {
        if (key < 0) {
            return this.buffer[this.pointer + key];
        } else if (key === false) {
            return this.buffer[this.pointer - 1];
        } else {
            return this.buffer[key];
        }
    }

    prevItem() {
        if (this.buffer[(this.pointer - 1) % this.length]) {
            return this.buffer[this.buffer[(this.pointer - 1) % this.length]];
        }
    }

    nextItem() {
        if (this.buffer[(this.pointer + 1) % this.length]) {
            return this.buffer[(this.pointer + 1) % this.length];
        }
    }

    currentItem() {
        return this.buffer[this.pointer];
    }

    setLogIncrement(inc) {
        this.logIncrement = inc;
    }

    getPointer() {
        return this.pointer;
    }

    setPointer(idx: number) {
        this.pointer = idx;
    }

    setDepth(sDepth) {
        this.depth = sDepth;
    }

    getDepth() {
        return this.depth;
    }

    getLength() {
        return this.length;
    }

    getPartialURL() {
        return this.logUrl;
    }

    setLowPoint(value) {
        this.loadPointLow = value;
    }

    setHighPoint(value) {
        this.loadPointHigh = value;
    }

    normalizeDepth(depth): number {
        const minDiff = Number(depth) - Number(this.minDepth);
        const modDiff = minDiff % Number(this.logIncrement);
        if (modDiff > 0) {
            depth -= modDiff;
        }
        return depth;
    }

    findStartIndex(value) {
        for (let i = 0; i < this.length; i++) {
            try {
                if (
                    Number(value) >=
                        this.normalizeDepth(this.buffer[i][0][0]) &&
                    Number(value) <
                        this.buffer[i][0][this.buffer[i][0].length - 1]
                ) {
                    return i;
                }
            } catch {}
        }
        return -1;
    }

    findStartDepth(value) {
        for (let i = 0; i < this.length; i++) {
            if (
                Math.floor(this.buffer[i][0][0]) <= value &&
                value < this.buffer[i][this.buffer[0].length - 1][0]
            ) {
                return Math.floor(this.buffer[i][0][0]);
            }
        }
        return -1;
    }

    findMin() {
        let i = 0;
        for (; i < this.length; i++) {
            if (!this.buffer[i]) {
                continue;
            } else {
                return i;
            }
        }
        return i;
    }

    findMax() {
        let i = 19;
        for (; i >= 0; i--) {
            if (!this.buffer[i]) {
                continue;
            } else {
                return i;
            }
        }
        return i;
    }

    getBuffer(idx) {
        return this.buffer[idx].slice(0);
    }

    getLogDataUBufferBlock(startDepth, endDepth): Promise<any> {
        if (!startDepth || !endDepth) {
            return new Promise(null);
        }

        const url = this.getPartialURL() + startDepth + '&endDepth=' + endDepth;
        return this.http.get(url, { responseType: 'text' }).toPromise().catch();
    }

    change(changeDepth) {
        let buff = null;

        if (changeDepth === this.depth) {
            buff = this.currentItem();
        }

        if (changeDepth > this.maxDepth || changeDepth < this.minDepth) {
            return null;
        }

        if (changeDepth > this.depth) {
            while (changeDepth > this.depth) {
                this.pointer = this.pointer + 1;

                if (this.pointer < 0) {
                    this.pointer = 19;
                }

                if (this.pointer > 19) {
                    this.pointer = 0;
                }

                if (this.nextDepth > 0 && !(this.nextDepth === changeDepth)) {
                    this.reload(1, changeDepth + 5 * this.logIncrement);
                    this.nextDepth = -1;
                } else {
                    this.nextDepth = -1;
                }

                if (
                    this.pointer === this.loadPointLow ||
                    this.pointer === this.loadPointHigh
                ) {
                    this.nextDepth = changeDepth + this.logIncrement;
                    this.loadNewAsc(
                        this.pointer,
                        changeDepth + 5 * this.logIncrement
                    );
                }

                buff = this.currentItem();
                this.depth += this.logIncrement;
            }
        }

        if (changeDepth < this.depth) {
            while (changeDepth < this.depth) {
                this.pointer = this.pointer - 1;

                if (this.pointer < 0) {
                    this.pointer = 19;
                }

                if (this.pointer > 19) {
                    this.pointer = 0;
                }

                if (this.nextDepth > 0 && !(this.nextDepth === changeDepth)) {
                    this.reload(-1, this.depth - 5 * this.logIncrement);
                    this.nextDepth = -1;
                } else {
                    this.nextDepth = -1;
                }

                if (
                    this.pointer === this.loadPointLow ||
                    this.pointer === this.loadPointHigh
                ) {
                    this.nextDepth = changeDepth - this.logIncrement;
                    this.loadNewDesc(
                        this.pointer,
                        changeDepth - 5 * this.logIncrement
                    );
                }

                buff = this.currentItem();
                this.depth -= this.logIncrement;
            }
        }

        this.depth = changeDepth;
        return buff;
    }

    getOneFootSegment(segmentDepth) {
        if (segmentDepth > this.maxDepth || segmentDepth < this.minDepth) {
            return null;
        }

        return this.findStartIndex(segmentDepth);
    }

    loadNewAsc(passPointer, startDepth) {
        return new Promise<void>((resolve, reject) => {
            let tmpPointer = passPointer + 5;
            let step: number = this.loadStep;
            const lastDepth =
                startDepth + this.logIncrement + 10 * this.logIncrement;
            let endDepth = startDepth + this.logIncrement;
            this.logFunction(startDepth, lastDepth).then(
                (data) => {
                    const pData = JSON.parse(data);
                    let k = 0;
                    while (step !== 0 && step !== -0) {
                        if (tmpPointer < 0) {
                            tmpPointer = this.length - 1;
                        }

                        if (tmpPointer > 19) {
                            tmpPointer = 0;
                        }

                        this.buffer[tmpPointer] = [];
                        this.buffer[tmpPointer].length = 0;
                        this.buffer[tmpPointer] = pData[k].map((e) => [...e]);
                        startDepth += this.logIncrement;
                        endDepth += this.logIncrement;
                        tmpPointer += 1;
                        k++;
                        step -= 1;
                    }
                    resolve();
                },
                (msg) => {
                    reject(msg);
                }
            );
        });
    }

    loadNewDesc(passPointer, startDepth) {
        return new Promise<void>((resolve, reject) => {
            let tmpPointer = passPointer - 5;
            let step: number = this.loadStep;
            const lastDepth =
                startDepth - this.logIncrement - 10 * this.logIncrement;
            let endDepth = startDepth + this.logIncrement;
            this.logFunction(lastDepth, startDepth + this.logIncrement).then(
                (data) => {
                    const pData = JSON.parse(data);
                    let k = pData.length - 1;
                    while (step !== 0 && step !== -0) {
                        if (tmpPointer < 0) {
                            tmpPointer = this.length - 1;
                        }

                        if (tmpPointer > 19) {
                            tmpPointer = 0;
                        }

                        this.buffer[tmpPointer] = [];
                        this.buffer[tmpPointer].length = 0;
                        this.buffer[tmpPointer] = pData[k].map((e) => [...e]);
                        startDepth -= this.logIncrement;
                        endDepth -= this.logIncrement;
                        tmpPointer -= 1;
                        k--;
                        step -= 1;
                    }
                    resolve();
                },
                (msg) => {
                    reject(msg);
                }
            );
        });
    }

    private reload(one, passDepth) {
        if (one > 0) {
            this.loadNewAsc(this.pointer + -one, passDepth);
        } else {
            this.loadNewDesc(this.pointer + -one, passDepth);
        }
    }
}
