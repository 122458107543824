import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { filter, map, tap, withLatestFrom, combineAll } from 'rxjs/operators';
import { WellStoreSelectors } from '../../well/store';
import { SlabImagesService } from '../../services';
import * as FeatureActions from './actions';
import { State } from '../../well/store/state';
import { selectCoreLabel, selectCoreFolder } from '../../ct-header/store/selectors';
import { selectSelectedImage } from './selectors';

@Injectable()
export class Effects {
    constructor(
        private actions$: Actions,
        private store$: Store<State>,
        private service: SlabImagesService,
        private router: Router
    ) {}

    route = 'slab';

    triggerLoadImages$ = createEffect(() =>
        this.store$.pipe(
            select(WellStoreSelectors.selectWell),
            filter((well) => !!well),
            map((well) => FeatureActions.loadImages({ well }))
        )
    );

    loadImages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FeatureActions.loadImages),
            map(({ well }) => this.service.getImagesFromWell(well)),
            map((images) => FeatureActions.loadImagesSuccess({ images })),
            tap(() =>
                this.router.navigate(['ct-scan/ct-main/grid'], {
                    queryParamsHandling: 'merge',
                })
            )
        )
    );

    navigateToSlab$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(FeatureActions.viewImage),
                withLatestFrom(this.store$.pipe(select(selectCoreFolder))),
                tap(([action, url]) => {
                    if (url.toUpperCase().includes('PLUG')) {
                        this.route = 'plug';
                    } else {
                        this.route = 'slab';
                    }
                    this.router.navigate([`ct-scan/ct-main/${this.route}`, {source: 'grid'} ],
                    {queryParamsHandling: 'merge',
                    });
                })
            ),
        { dispatch: false }
    );
}
