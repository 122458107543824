import { createReducer, Action, on } from '@ngrx/store';
import { initialState, State } from './state';
import * as FeatureActions from './actions';
import { SlabImage } from '../../models';

const featureReducer = createReducer(
    initialState,
    on(FeatureActions.changeDepthIndication, (state, { depthIndication }) => ({
        ...state,
        depthIndication,
    })),
    on(FeatureActions.changeDepthRangeSuccess, (state, { depthRange }) => ({
        ...state,
        depthRange,
    })),
    on(FeatureActions.loadCTLogsSuccess, (state, { logs }) => ({
        ...state,
        logs,
    })),
    on(FeatureActions.changeIndicatorYOffset, (state, { offset }) => ({
        ...state,
        offset,
    }))
);

export function reducer(state: State | undefined, action: Action): State {
    return featureReducer(state, action);
}
