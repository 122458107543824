import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, featureKey } from './state';

const selectState = createFeatureSelector<State>(featureKey);

export const selectThreeDPlug = createSelector(
    selectState,
    (state) => state.threeDPlug
);

export const selectIsLoading = createSelector(
    selectState,
    (state) => state.isLoading
);
